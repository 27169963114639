<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateFormatted"
                :label="label"
                hint="DD/MM/YYYY format"
                persistent-hint
                prepend-icon="fas fa-calendar"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            @input="
                menu2 = false;
                updateDate($event);
            "
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: "DatePicker",

    props: {
        value: {
            type: String,
            required: false,
            default: "",
        },

        inValid: {
            type: Boolean,
            required: false,
            default: false,
        },

        label: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            dateFormatted: this.formatDate(
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
            ),
            menu: false,
        };
    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date);
        },
    },

    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        parseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },

        updateDate(event) {
            if (this.date) {
                this.$emit("input", this.date);
                this.$emit("update:inValid", false);
            } else {
                this.$emit("update:inValid", true);
            }
        },
    },

    watch: {
        value(newVal) {
            this.date = newVal;
        },
    },

    mounted() {
        this.$emit("input", this.date);
        this.$emit("update:inValid", false);
    },
};
</script>
